import tippy from "tippy.js";
import style from "./style.scss";
//import "tippy.js/index.css";
import * as React from "react";
//import render from "preact-render-to-string";

export default class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.tooltipRef;
  }

  componentDidMount = () => {
    const template = (
      <div className="tooltip-content-wrap">
        <button
          aria-label="Close tooltip"
          id="close-tooltip"
          className="close-tooltip"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              fill="#FFF"
              fillRule="evenodd"
              d="M8.82658275,7.50026032 L14.7251465,1.60169661 C15.0916178,1.23553789 15.0916178,0.641428944 14.7251465,0.275270224 C14.358675,-0.091201186 13.7651915,-0.091201186 13.3987201,0.275270224 L7.50015634,6.17383394 L1.60127994,0.275270224 C1.23480853,-0.091201186 0.641324967,-0.091201186 0.274853557,0.275270224 C-0.0916178521,0.641428944 -0.0916178521,1.23553789 0.274853557,1.60169661 L6.17372996,7.50026032 L0.274853557,13.3988241 C-0.0916178521,13.7649827 -0.0916178521,14.3590917 0.274853557,14.7252504 C0.458089262,14.9081735 0.69823435,14.9997913 0.938066748,14.9997913 C1.17789914,14.9997913 1.41804423,14.9081735 1.60127994,14.7249377 L7.50015634,8.82637401 L13.3987201,14.7249377 C13.5819557,14.9081735 13.8221008,14.9997913 14.0619333,14.9997913 C14.3017656,14.9997913 14.5419107,14.9081735 14.7251465,14.7249377 C15.0916178,14.358779 15.0916178,13.7646701 14.7251465,13.3985113 L8.82658275,7.50026032 Z"
            />
          </svg>
        </button>
        {this.props.content}
      </div>
    );

    tippy(this.tooltipRef, {
      content: template,
      arrowType: "round",
      arrow: true,
      trigger: "click",
      theme: "interactive-module",
      interactive: true,
      onShown: (tippyInstance) => {
        const closeButton = document.getElementById("close-tooltip");
        closeButton.focus();

        closeButton.addEventListener("click", () => {
          tippyInstance.hide();
        });

        // Close on escape key
        this.hideTippyOnEsc = (event) => {
          var key = event.key || event.keyCode;

          if (key === "Escape" || key === "Esc" || key === 27) {
            tippyInstance.hide();
          }
        };
        document.addEventListener("keyup", this.hideTippyOnEsc);
      },
      onHidden: () => {
        document.removeEventListener("keyup", this.hideTippyOnEsc);
      },
    });
  };

  handleClick = (event) => {
    event.preventDefault();
  };

  render() {
    const { label } = this.props;
    return (
      <a
        onClick={this.handleClick}
        href="#"
        ref={(tooltip) => (this.tooltipRef = tooltip)}
        className="tooltip"
      >
        {label}
      </a>
    );
  }
}

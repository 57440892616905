import * as React from "react";

const SHARED_MODAL = "accounts";

export default class AccountModal extends React.Component {
  constructor() {
    super();

    if (this.constructor.name === "Modal") {
      throw new Error("Cannot create instance of abstract class Modal.");
    }

    ["validate", "submit", "handleErrors"].forEach((method) => {
      if (typeof this[method] !== "function") {
        throw new Error("Abstract method not defined: " + method);
      }
    });

    this.state = {
      loading: false,
      generalError: null,
    };
  }

  clearErrors() {
    this.setState({
      generalError: null,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.clearErrors();
    if (!this.validate()) {
      return;
    }

    this.setState({ loading: true });

    this.submit()
      .catch(this.handleErrors.bind(this))
      .finally(() => {
        this.setState({ loading: false });
      });
  }
}

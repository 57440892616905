import * as React from "react";

export default class TextInput extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Check if input has errors
   *
   * @returns {boolean}
   */
  hasErrors() {
    return this.props.errors !== undefined && this.props.errors.length;
  }

  /**
   * Event handler input change
   *
   * @param {Object} event
   */
  handleInputChange = (event) => {
    const { onInput } = this.props;

    if (onInput != null) {
      onInput(event);
    }
  };

  render() {
    const { name, label, value, errors, required } = this.props;
    return (
      <div
        className={
          this.hasErrors()
            ? "has-errors input-group cope-core--form--field"
            : "input-group cope-core--form--field"
        }
      >
        {label && (
          <div className="cope-core-text text-headline-5">
            <label className={required && "required"} htmlFor={name}>
              {label}
            </label>
          </div>
        )}
        <div className="error-wrap">
          <div className="input-wrap">
            <input
              type="text"
              onInput={this.handleInputChange}
              id={name}
              name={name}
              value={value || ""}
              aria-required={required ? "true" : false}
            />
          </div>
          {this.hasErrors()
            ? errors.map((error) => (
                <div
                  key={error}
                  className="cope-core-text text-body-1 cope-core--form--error error-message"
                >
                  <p>{error}</p>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}

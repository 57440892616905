import * as React from "react";

import "./style.scss";

export default class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.openLink = React.createRef();
    this.closeLink = React.createRef();

    this.state = {
      children: null,
    };
  }

  open() {
    if (this.openLink.current) {
      this.openLink.current.click();
    }
  }

  close() {
    if (this.closeLink.current) {
      this.closeLink.current.click();
    }

    this.setChildren(null);
  }

  setChildren(children) {
    this.setState({ children });
  }

  render() {
    const { id } = this.props;

    return (
      <div className="cope-core--modal--wrapper">
        <div
          className="cope-core--modal--background"
          onClick={this.close.bind(this)}
        ></div>
        <div
          id={id}
          className="cope-core--modal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <a
            data-modal-target={"#" + id}
            data-modal-trigger-open
            style={{ display: "none" }}
            ref={this.openLink}
          />

          <button
            className="cope-core--modal--close"
            data-modal-trigger-close
            onClick={() => {
              this.setChildren(null);
            }}
            ref={this.closeLink}
          >
            <svg viewBox="0 0 46 46" version="1.1">
              <g
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="square"
              >
                <g
                  className="foreground"
                  transform="translate(1.000000, 1.000000)"
                  stroke="#CCCCCC"
                >
                  <line x1="43.45" y1="0.55" x2="0.55" y2="43.45" />
                  <line
                    x1="43.45"
                    y1="0.55"
                    x2="0.55"
                    y2="43.45"
                    transform="translate(22.000000, 22.000000) scale(-1, 1) translate(-22.000000, -22.000000) "
                  />
                </g>
              </g>
            </svg>
          </button>
          {this.state.children || this.props.children}
        </div>
      </div>
    );
  }
}

// Please don't edit this file. It was automatically generated, and any changes
// you make will be overwritten the next time it is regenerated.
//
// This file defines a single exported function:
//
//    createClient(options)
//
// This returns an object whose values are async functions that can be used to
// interact with an API.
//
// For more information, see the associated README.md file.
//
/// <summary>
///
/// </summary>
export var AnonymousType18
;(function(AnonymousType18) {
  AnonymousType18['Password'] = 'password'
  AnonymousType18['RefreshToken'] = 'refresh_token'
})(AnonymousType18 || (AnonymousType18 = {}))
/// <summary>
///
/// </summary>
export var Diagnosis
;(function(Diagnosis) {
  Diagnosis['None'] = 'none'
  Diagnosis['Aghd'] = 'aghd'
  Diagnosis['Cghd'] = 'cghd'
  Diagnosis['Smallgest'] = 'smallgest'
  Diagnosis['Noonan'] = 'noonan'
  Diagnosis['Turner'] = 'turner'
  Diagnosis['Praderwilli'] = 'praderwilli'
  Diagnosis['Iss'] = 'iss'
})(Diagnosis || (Diagnosis = {}))
/// <summary>
///
/// </summary>
export var ErrorCode
;(function(ErrorCode) {
  ErrorCode['AccountLocked'] = 'accountLocked'
  ErrorCode['Unknown'] = 'unknown'
})(ErrorCode || (ErrorCode = {}))
/// <summary>
///
/// </summary>
export var Reason
;(function(Reason) {
  Reason['Other'] = 'other'
  Reason['Considering'] = 'considering'
  Reason['Taking'] = 'taking'
})(Reason || (Reason = {}))
/// <summary>
///
/// </summary>
export var UserType
;(function(UserType) {
  UserType['Patient'] = 'patient'
  UserType['Caregiver'] = 'caregiver'
  UserType['Other'] = 'other'
})(UserType || (UserType = {}))
/// <summary>
///
/// </summary>
export var ValidationErrorCode
;(function(ValidationErrorCode) {
  ValidationErrorCode['Invalid'] = 'invalid'
  ValidationErrorCode['NotFound'] = 'notFound'
  ValidationErrorCode['Required'] = 'required'
})(ValidationErrorCode || (ValidationErrorCode = {}))
const DEFAULT_API_URL = 'https://account.nnipatient.com'
const OPERATIONS = {
  completePasswordReset: {
    consumes: {
      json: 'application/json'
    },
    formDataParameters: [],
    method: 'post',
    operationId: 'completePasswordReset',
    path: '/api/password/reset',
    pathParameters: [],
    produces: {
      json: 'application/json'
    },
    responses: {
      '200': {
        hasBody: true,
        isOAuth2GrantFor: 'norditropin-account'
      },
      '400': {
        hasBody: true
      },
      '429': {
        hasBody: true
      },
      '500': {
        hasBody: true
      }
    }
  },
  createUser: {
    consumes: {
      json: 'application/json'
    },
    formDataParameters: [],
    method: 'post',
    operationId: 'createUser',
    path: '/api/users',
    pathParameters: [],
    produces: {
      json: 'application/json'
    },
    responses: {
      '201': {
        hasBody: true,
        isOAuth2GrantFor: 'norditropin-account'
      },
      '400': {
        hasBody: true
      },
      '429': {
        hasBody: true
      }
    }
  },
  getCurrentUser: {
    consumes: {
      json: 'application/json'
    },
    formDataParameters: [],
    method: 'get',
    oauth2: {
      name: 'norditropin-account',
      operationId: 'oauthGrant',
      scopes: ['user']
    },
    operationId: 'getCurrentUser',
    path: '/api/users/me',
    pathParameters: [],
    produces: {
      json: 'application/json'
    },
    responses: {
      '200': {
        hasBody: true
      },
      '401': {},
      '500': {
        hasBody: true
      }
    }
  },
  initiatePasswordReset: {
    consumes: {
      json: 'application/json'
    },
    formDataParameters: [],
    method: 'post',
    operationId: 'initiatePasswordReset',
    path: '/api/password/forgot',
    pathParameters: [],
    produces: {
      json: 'application/json'
    },
    responses: {
      '200': {},
      '400': {
        hasBody: true
      },
      '429': {
        hasBody: true
      },
      '500': {
        hasBody: true
      }
    }
  },
  logIn: {
    consumes: {
      json: 'application/json'
    },
    formDataParameters: [],
    method: 'post',
    operationId: 'logIn',
    path: '/api/sessions',
    pathParameters: [],
    produces: {
      json: 'application/json'
    },
    responses: {
      '200': {
        hasBody: true,
        isOAuth2GrantFor: 'norditropin-account'
      },
      '400': {
        hasBody: true
      },
      '429': {
        hasBody: true
      }
    }
  },
  oauthGrant: {
    consumes: {
      urlEncoded: 'application/x-www-form-urlencoded'
    },
    formDataParameters: ['grant_type', 'username', 'password', 'refresh_token'],
    isOAuth2TokenEndpoint: true,
    method: 'post',
    operationId: 'oauthGrant',
    path: '/api/token',
    pathParameters: [],
    produces: {
      json: 'application/json'
    },
    responses: {
      '200': {
        hasBody: true
      },
      '400': {
        hasBody: true
      }
    }
  },
  updateAddressLikeNml: {
    consumes: {
      json: 'application/json'
    },
    formDataParameters: [],
    method: 'put',
    oauth2: {
      name: 'norditropin-account',
      operationId: 'oauthGrant',
      scopes: ['user']
    },
    operationId: 'updateAddressLikeNml',
    path: '/api/nml-compat/users/me/address',
    pathParameters: [],
    produces: {
      json: 'application/json'
    },
    responses: {
      '200': {},
      '400': {},
      '401': {},
      '500': {
        hasBody: true
      }
    }
  },
  updateCurrentUser: {
    consumes: {
      json: 'application/json'
    },
    formDataParameters: [],
    method: 'put',
    oauth2: {
      name: 'norditropin-account',
      operationId: 'oauthGrant',
      scopes: ['user']
    },
    operationId: 'updateCurrentUser',
    path: '/api/users/me',
    pathParameters: [],
    produces: {
      json: 'application/json'
    },
    responses: {
      '200': {},
      '400': {
        hasBody: true
      },
      '401': {},
      '500': {
        hasBody: true
      }
    }
  }
}
const NOOP = function() {}
export function createClient(options = {}) {
  let fetch = options.fetch
  if (!fetch) {
    if (typeof window === 'undefined' || typeof window.fetch !== 'function') {
      throw new Error(
        'window.fetch is not available. You must either include an appropriate polyfill or pass the `fetch` option to createClient().'
      )
    }
    fetch = window.fetch
  }
  let createFormData = options.createFormData
  if (!createFormData) {
    if (typeof FormData === 'undefined') {
      throw new Error(
        'FormData is not available. You must either include an appropriate polyfill or pass the `createFormData` option to createClient().'
      )
    }
    createFormData = () => new FormData()
  }
  const jsonStringify = options.jsonStringify || JSON.stringify
  const globalMiddleware = [
    ...(options.middleware || []),
    createOAuth2Middleware(options),
    ...[].map(headerName => createApiKeyMiddleware(headerName, options))
  ]
  const log =
    options.log === true ? console.log.bind(console) : options.log || NOOP
  const logRequests =
    options.logRequests === true
      ? console.log.bind(console)
      : options.logRequests || NOOP
  const logResponses =
    options.logResponses === true
      ? console.log.bind(console)
      : options.logResponses || NOOP
  return {
    completePasswordReset(request) {
      return execute(OPERATIONS['completePasswordReset'], [], request)
    },
    createUser(user) {
      return execute(OPERATIONS['createUser'], [], user)
    },
    getCurrentUser() {
      return execute(OPERATIONS['getCurrentUser'], [], undefined)
    },
    initiatePasswordReset(request) {
      return execute(OPERATIONS['initiatePasswordReset'], [], request)
    },
    logIn(credentials) {
      return execute(OPERATIONS['logIn'], [], credentials)
    },
    oauthGrant(formData) {
      return execute(OPERATIONS['oauthGrant'], [], formData)
    },
    updateAddressLikeNml(address) {
      return execute(OPERATIONS['updateAddressLikeNml'], [], address)
    },
    updateCurrentUser(request) {
      return execute(OPERATIONS['updateCurrentUser'], [], request)
    }
  }
  async function execute(op, pathParameters, bodyParameter) {
    const url = buildUrl(op, pathParameters)
    const apiRequestOptions = buildApiRequestOptions(op, bodyParameter)
    // These middleware are executed first-to-last, with each being passed a next() function
    // they can use to invoke the following one.
    const middlewareForRequest = [
      // First, include any middleware that apply to all operations. This includes
      // middleware passed in as an option to `createClient()`.
      ...globalMiddleware,
      // Next, include "core" middleware that does things like translating 4xx and 5xx responses
      // to errors and logging requests and responses.
      errorReportingMiddleware,
      requestLoggingMiddleware,
      // Finally, a "middleware" that actually executes fetch(). This is the end of the chain.
      async (op, url, options) => {
        const response = await fetch(url, options)
        return await normalizeFetchResponse(op, response)
      }
    ]
    const response = await runMiddleware(0, op, url, apiRequestOptions)
    return response.parsedBody
    async function runMiddleware(index, op, url, options) {
      const middleware = middlewareForRequest[index]
      if (typeof middleware !== 'function') {
        throw new Error(
          `Middleware ${index} should be a function, but is ${typeof middleware}.`
        )
      }
      const next = runMiddleware.bind(null, index + 1)
      const result = await middleware(op, url, options, next)
      if (result == null) {
        throw new Error(`Middleware ${middleware} did not return anything!`)
      }
      return result
    }
  }
  /**
   * Middleware that does custom apikey authentication.
   */
  function createApiKeyMiddleware(headerName, options) {
    let apiKey = options.apiKey || options.authToken
    const onNewApiKeyReceived =
      options.onNewApiKeyReceived || options.onNewAuthTokenReceived || NOOP
    const newApiKeyHeaderName =
      options.newApiKeyHeaderName || options.authTokenHeaderName || headerName
    const useBearerAuthorization =
      options.useBearerAuthorizationForApiKey === undefined
        ? true
        : !!options.useBearerAuthorizationForApiKey
    return async function apiKeyMiddleware(op, url, options, next) {
      const shouldAddHeader = !!(op.apiKey && headerName != null)
      if (shouldAddHeader && apiKey != null) {
        let name, value
        if (useBearerAuthorization) {
          name = 'authorization'
          value = `Bearer ${apiKey}`
        } else {
          name = headerName.toLowerCase()
          value = apiKey
        }
        log('apiKey: setting %s header to %s', name, value)
        options.headers[name] = value
      }
      const response = await next(op, url, options)
      detectNewApiKey(response)
      return response
      function detectNewApiKey(response) {
        if (!newApiKeyHeaderName) {
          return
        }
        const newApiKey =
          response.headers[String(newApiKeyHeaderName).toLowerCase()]
        handleNewApiKey(newApiKey)
      }
      function handleNewApiKey(newApiKey) {
        if (newApiKey == null) {
          return
        }
        log('received new api key %s', newApiKey)
        apiKey = newApiKey
        onNewApiKeyReceived(newApiKey)
      }
    }
  }
  /**
   * Middleware that manages OAuth token exchange.
   */
  function createOAuth2Middleware(apiClientOptions) {
    apiClientOptions = apiClientOptions || {}
    let knownGrants = [...(apiClientOptions.oauth2Grants || []).filter(x => x)]
    const onNewGrant =
      typeof apiClientOptions.onNewOAuth2Grant === 'function'
        ? apiClientOptions.onNewOAuth2Grant
        : NOOP
    return async function oauth2Middleware(op, url, options, next) {
      let response
      let grant = getGrant()
      if (apiClientOptions.forceOAuth2TokenRefresh) {
        // Do the refresh_token call *first*
        grant = await doTokenRefresh(grant)
      }
      try {
        response = await doRequest(grant)
      } catch (err) {
        if (!err._response) {
          // This was not an application-level error, but was likely either a
          // transport error (i.e. connection timed out) or another JS error.
          // Pass the error along to the caller so that they can choose how
          // to handle it.
          throw err
        }
        if (err._response.status !== 401) {
          // This *was* an application-level error, but it wasn't a 401
          // Unauthorized response, so let the error pass through.
          throw err
        }
        if (!grant) {
          // We do not have an OAuth grant that could potentially be refreshed.
          // So we have no choice here but to pass the 401 Unauthorized error
          // on to the caller and let them deal with it.
          throw err
        }
        grant = await doTokenRefresh(grant)
        if (!grant) {
          // Token refresh failed for some reason, so pass on the original
          // 401 Unauthorized error to the caller.
          throw err
        }
        // Retry the original request with the (now refreshed) OAuth grant
        response = await doRequest(grant)
      }
      const newGrant = detectNewGrant(op, response)
      if (newGrant) {
        handleNewGrant(newGrant)
      }
      return response
      function detectNewGrant(op, response) {
        if (wasOAuthGrant()) {
          return response.parsedBody
        }
        if (typeof apiClientOptions.detectOAuth2Grant !== 'function') {
          return false
        }
        const customDetectionResult = apiClientOptions.detectOAuth2Grant(
          op,
          response
        )
        // Allow users to just say "yep, that's a grant"
        if (customDetectionResult === true) {
          return response.parsedBody
        } else if (customDetectionResult == null) {
          return
        } else {
          // TODO: Validate that it actually *looks* like a grant.
          return customDetectionResult
        }
        function wasOAuthGrant() {
          if (op.isOAuth2TokenEndpoint) {
            return true
          }
          // Responses in spec can be marked with `x-isOAuth2GrantFor: "security-requirement-name"`
          const responseSpec = op.responses[response.status]
          if (responseSpec.isOAuth2GrantFor) {
            // TODO: Verify that isOAuth2GrantFor is for a valid security requirement.
            log(
              'auto-detecting oauth2 grant for %s',
              responseSpec.isOAuth2GrantFor
            )
            return true
          }
          return false
        }
      }
      async function doTokenRefresh(grant) {
        if (!op.oauth2) {
          // This operation does not use OAuth.
          return
        }
        if (op.isOAuth2TokenEndpoint) {
          // Don't try to refresh token while calling OAuth.
          // This could happen if the OAuth endpoint was mistakenly marked as
          // requiring authorization.
          throw new Error(
            `Can't make refresh_token grant before call to ${op.operationId}.`
          )
        }
        const oauthOp = OPERATIONS[op.oauth2.operationId]
        let refreshTokenGrant
        try {
          refreshTokenGrant = await execute(oauthOp, [], {
            grant_type: 'refresh_token',
            refresh_token: grant.refresh_token,
            scope: grant.scope
          })
        } catch (err) {
          if (err && err._response && err._response.status === 400) {
            // OAuth server is returning "Bad Request". This is *probably*
            // an `invalid_grant` error, meaning the refresh_token is no
            // longer valid. From the caller's point of view, this is not
            // an interesting error -- they are more interested in the original
            // `401 Unauthorized` error. So we swallow this one.
            log('refresh_token resulted in 400 Bad Request response.', err)
            return
          }
          // Other errors will (hopefully) be less common and may be more
          // interesting to the caller.
          throw err
        }
        if (!refreshTokenGrant) {
          return
        }
        return handleNewGrant(refreshTokenGrant)
      }
      function doRequest(grant) {
        if (grant) {
          options.headers['Authorization'] = `Bearer ${grant.access_token}`
        }
        return next(op, url, options)
      }
      function getGrant() {
        if (!op.oauth2) {
          return
        }
        // TODO: Support > 1 scope.
        if (op.oauth2.scopes.length > 1) {
          throw new Error(
            `${
              op.operationId
            } has more than 1 OAuth scope. This is not currently supported.`
          )
        }
        const scope = op.oauth2.scopes[0]
        if (!scope) {
          return
        }
        return knownGrants.find(g => g.scope === scope)
      }
      function handleNewGrant(newGrant) {
        if (!newGrant) {
          return
        }
        knownGrants = [
          newGrant,
          // Remove any existing grants for the same scope, since newGrant presumably overrides those now.
          ...knownGrants.filter(g => g.scope !== newGrant.scope)
        ]
        onNewGrant(newGrant)
        return newGrant
      }
    }
  }
  async function requestLoggingMiddleware(op, url, options, next) {
    if (logRequests) {
      logRequests(
        '(%s) %s %s\n%O\n%O',
        op.operationId,
        options.method,
        url,
        options.headers,
        options.body
      )
    }
    const response = await next(op, url, options)
    if (logResponses) {
      logResponses(
        '(%s) %d\n%O\n%O',
        op.operationId,
        response.status,
        response.headers,
        response.parsedBody == null ? response.body : response.parsedBody
      )
    }
    return response
  }
  function buildUrl(op, pathParameterValues) {
    let path = op.path
    for (let i = 0; i < op.pathParameters.length; i++) {
      const name = op.pathParameters[i]
      const value = pathParameterValues[i]
      path = path.replace('{' + name + '}', encodeURIComponent(value))
    }
    return [options.apiUrl || DEFAULT_API_URL, path].join('')
  }
  function buildApiRequestOptions(op, bodyParameter) {
    const request = {
      body: undefined,
      method: op.method,
      headers: {}
    }
    if (bodyParameter === undefined) {
      return request
    }
    if (op.consumes.json) {
      setJsonPayload()
    } else if (op.consumes.urlEncoded) {
      setUrlEncodedPayload()
    } else if (op.consumes.multipart) {
      setMultipartPayload()
    } else {
      throw new Error(`Can't format payload for ${op.operationId}`)
    }
    return request
    function setJsonPayload() {
      request.headers['Content-Type'] = op.consumes.json
      request.body = jsonStringify(bodyParameter)
    }
    function setUrlEncodedPayload() {
      request.headers['Content-Type'] = op.consumes.urlEncoded
      request.body = Object.keys(bodyParameter)
        .map(
          key =>
            `${encodeURIComponent(key)}=${encodeURIComponent(
              bodyParameter[key]
            )}`
        )
        .join('&')
    }
    function setMultipartPayload() {
      // NOTE: fetch() automatically sets content type with FormData body.
      const formData = createFormData()
      Object.keys(bodyParameter).forEach(key => {
        formData.append(key, bodyParameter[key])
      })
      request.body = formData
    }
  }
  /**
   * Middleware that takes "error" reponses (in the 4xx-5xx range) and passes them on via a
   * rejected promise.
   */
  function errorReportingMiddleware(op, url, options, next) {
    return next(op, url, options).then(response => {
      if (response.status < 400) {
        return response
      }
      const expectedResponse = op.responses[response.status]
      // NOTE: "unexpected status" is checked for in normalizeFetchResponse().
      if (expectedResponse.hasBody && response.parsedBody) {
        // Pass the response body along as the actual error, but include some metadata
        const err = new Error(response.parsedBody.message)
        return Promise.reject(
          Object.assign(err, { _response: response }, response.parsedBody)
        )
      }
      // No response body defined or available, so throw a generic error.
      throw createError(
        `http_status_${response.status}`,
        `Request returned a response with HTTP status code ${response.status}`,
        response
      )
    })
  }
  /**
   * Takes a response from fetch() and does some basic processing to put in an easier-to-use form.
   */
  async function normalizeFetchResponse(op, response) {
    const { status } = response
    const headers = {}
    for (let pair of response.headers.entries()) {
      headers[pair[0].toLowerCase()] = pair[1]
    }
    const rawBody = await response.text()
    const expectedResponse = op.responses[status]
    if (!expectedResponse) {
      throw createError(
        'unexpected_status',
        `Received unexpected status code ${response.status}`,
        { status, headers, rawBody }
      )
    }
    const parsedBody = expectedResponse.hasBody
      ? parseResponseBody()
      : undefined
    return {
      status,
      headers,
      rawBody,
      parsedBody
    }
    function parseResponseBody() {
      const contentType = headers['content-type']
      if (
        op.produces.json &&
        isContentTypeMatch(contentType, op.produces.json)
      ) {
        return parseAsJSON()
      }
      if (
        op.produces.urlEncoded &&
        isContentTypeMatch(contentType, op.produces.urlEncoded)
      ) {
        return parseAsUrlEncoded()
      }
      if (
        op.produces.text &&
        isContentTypeMatch(contentType, op.produces.text)
      ) {
        return parseAsText()
      }
      throw createError(
        'invalid_content_type',
        `Cannot parse content type '${contentType}'`
      )
      function isContentTypeMatch(x, y) {
        // TODO: More sophisticated content-type matching, including support for encoding
        x = x.toLowerCase()
        y = y.toLowerCase()
        return x.indexOf(y) >= 0 || y.indexOf(x) >= 0
      }
    }
    function parseAsJSON() {
      return JSON.parse(rawBody)
    }
    function parseAsUrlEncoded() {
      const pairs = rawBody.split('&')
      const result = {}
      pairs.forEach(pair => {
        const m = /^(.*?)=(.*)$/.exec(pair)
        const key = decodeURIComponent(m ? m[1] : pair)
        const value = m ? decodeURIComponent(m[2]) : ''
        result[key] = value
      })
      return result
    }
    function parseAsText() {
      return rawBody
    }
  }
  function createError(code, message, response) {
    const err = new Error(message)
    err.code = code
    if (response) {
      err._response = response
    }
    return err
  }
}

/**
 * Set OAuth2 grant
 *
 * @param {object} grant
 */
export function setOAuth2Grant(grant) {
  localStorage.setItem('norditropinOAuth2Grant', JSON.stringify(grant))
}

/**
 * Get OAuth2Grant from localStorage
 *
 * @returns {array} Looks something like this:
 * [
 *   {
 *     access_token: "foo",
 *     refresh_token: "bar",
 *     expires_in: 300,
 *     scope: "baz"
 *   }
 * ]
 * Or empty array if norditropinOAuth2Grant does not exist
 */
export function getOAuth2Grants() {
  const grant = localStorage.getItem('norditropinOAuth2Grant')
  return grant ? [JSON.parse(grant)] : []
}

import * as React from "react";
import "./style.scss";

function getSizeClass(size) {
  switch (size) {
    case "sm":
      return "cope-core-button-type-sm";
    default:
      return "";
  }
}

export default class SubmitButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, loading, size } = this.props;
    return (
      <button
        className={`cope-core-button cope-core--button--primary ${
          loading ? "disabled" : ""
        } ${getSizeClass(size)}`}
        type="submit"
        disabled={loading ? true : false}
      >
        {loading ? <div className="loader" /> : null}
        <span>{label}</span>
      </button>
    );
  }
}

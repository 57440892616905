import * as React from "react";
import AccountModal from "./account-modal";
import { validateEmail, getSource } from "common/helpers";
import { showForgotPasswordSuccessModal } from "./forgot-password-success-modal";
import { openModal, setSharedModalContent } from "common/shared-modal";

export function showForgotPasswordModal() {
  const modal = <ForgotPasswordModal />;
  openModal("accounts");
  setSharedModalContent("accounts", modal);
}

function getErrorCode(errors, field) {
  const error = errors && errors.find((x) => x.field === field);
  return error && typeof error.code === "string" && error.code;
}

function getEmailErrorMessage(errorCode) {
  switch (errorCode) {
    case "notFound":
      return "No account exists for the email address.";
    default:
      return "Please enter a valid email address.";
  }
}

class ForgotPasswordModal extends AccountModal {
  constructor() {
    super();

    this.email = React.createRef();

    this.state = {
      emailError: false,
    };
  }

  handleErrors(response) {
    const emailError = getErrorCode(response.errors, "email");
    this.setState({
      emailError,
      generalError:
        !emailError && "We're sorry. An error has occured. Please try again.",
    });
  }

  clearErrors() {
    this.setState({ emailError: false });
    super.clearErrors();
  }

  submit() {
    return BiopharmExtensions.request
      .post("api/account/password/forgot", {
        body: JSON.stringify({
          email: this.email.current.value,
          source: getSource() + "-signup",
        }),
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (!response.ok) {
          response.json().then((json) => {
            this.handleErrors(json);
          });
        } else {
          showForgotPasswordSuccessModal(this.email.current.value);
        }
      })
      .catch((response) => {
        this.handleErrors(response);
      });
  }

  validate() {
    if (
      !(
        this.email.current &&
        this.email.current instanceof HTMLInputElement &&
        this.email.current.value &&
        typeof this.email.current.value === "string" &&
        validateEmail(this.email.current.value)
      )
    ) {
      this.setState({ emailError: true });
      return false;
    }

    return true;
  }

  render() {
    return (
      <div className="cope-core--modal--content cope-core-forgot-password-modal">
        <h2>Forgot your password?</h2>
        <div>
          <div className="cope-core-text text-body-xl">
            <p>
              Enter the email address associated with your account, and
              we&lsquo;ll email you a link to a page where you can reset your
              password.
            </p>
          </div>
        </div>
        <form className="cope-core--form cope-core--modal--form">
          {this.state.generalError && (
            <div
              className="cope-core--modal--form-field-error"
              style={{ display: "block" }}
            >
              {this.state.generalError}
            </div>
          )}
          <div className="cope-core--form--field cope-core--modal--form-field">
            <label className={this.state.emailError ? "has-error" : ""}>
              <div className="cope-core-text text-headline-5">
                <p>Email address</p>
              </div>
              <input
                type="text"
                className="cope-core-patient-account-buttons--forgot-password-modal--email"
                name="email"
                autoFocus
                ref={this.email}
              />
            </label>
            <p className="cope-core--form--error cope-core--modal--form-field-error">
              {getEmailErrorMessage(this.state.emailError)}
            </p>
          </div>
          <div className="cope-core--modal--buttons">
            <button
              type="submit"
              className="cope-core-button cope-core--button--primary cope-core-patient-account-buttons__forgot-password-modal--submit"
              onClick={this.onSubmit.bind(this)}
            >
              Reset password
            </button>
            <span className="cope-core-text text-headline-5">
              <a
                className="cope-core-patient-account-buttons__forgot-password-modal--back underlined"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  BiopharmExtensions.accounts.openLoginModal();
                }}
              >
                Back to log in
              </a>
            </span>
          </div>
        </form>
      </div>
    );
  }
}

import * as React from "react";
import intl from "react-intl-universal";
import { trackEvent, getSource } from "common/helpers";
import ConfirmInput from "common/components/partials/inputs/confirm";
import ButtonGroup from "common/components/partials/inputs/button-group";
import Button from "common/components/partials/inputs/button";
import {
  closeModal,
  openModal,
  setSharedModalContent,
} from "common/shared-modal";
import { showUpdateForm } from "common/components/pages/update-info";

const MODAL_ID = "accounts";

const HIDE_MODAL_KEY = "hide-user-profile-check";

// For historical reasons need to support old norditropin storage key
const HIDE_MODAL_KEYS = [HIDE_MODAL_KEY, "hide-norditropin-user-profile-check"];

const MODAL_NAME = "Update Account Modal";

export function showProfileCheckIfNecessary() {
  const shoudShowProfileCheck = HIDE_MODAL_KEYS.every(
    (key) => !sessionStorage.getItem(key) && !localStorage.getItem(key)
  );
  if (shoudShowProfileCheck) {
    setSharedModalContent(MODAL_ID, <UserProfileCheck />);
    openModal(MODAL_ID);
  }
}

export function clearProfileCheckSessionStorage() {
  sessionStorage.removeItem(HIDE_MODAL_KEY);
}

class UserProfileCheck extends React.Component {
  constructor(props) {
    super(props);
  }

  onNoButtonClicked(event) {
    event.preventDefault();

    this.hide();

    trackEvent(MODAL_NAME, "Declined");
  }

  onYesButtonClicked(event) {
    event.preventDefault();

    this.hide();

    trackEvent(MODAL_NAME, "Accepted");

    showUpdateForm();
  }

  hide() {
    const dontAskAgain = document.body.querySelector(
      "#account-update-check--permanent-hide"
    );
    if (dontAskAgain.checked) {
      localStorage.setItem(HIDE_MODAL_KEY, true);
    }

    closeModal(MODAL_ID);

    sessionStorage.setItem(HIDE_MODAL_KEY, true);
  }

  render() {
    return (
      <div className="cope-core--accounts--profile-check">
        <div className="cope-core-text text-headline-2">
          <h2>{intl.getHTML("userDataCheck.heading-" + getSource())}</h2>
        </div>
        <div className="cope-core-text text-body-xl">
          <p>{intl.get("userDataCheck.text")}</p>
        </div>
        <ButtonGroup>
          <Button
            primary
            size="sm"
            onClick={this.onYesButtonClicked.bind(this)}
            label={intl.get("userDataCheck.buttonLabels.yes")}
          />
          <Button
            secondary
            size="sm"
            onClick={this.onNoButtonClicked.bind(this)}
            label={intl.get("userDataCheck.buttonLabels.no")}
          />
        </ButtonGroup>
        <ConfirmInput
          name="account-update-check--permanent-hide"
          label={<span>{intl.get("userDataCheck.dontAskAgain")}</span>}
        />
      </div>
    );
  }
}

import * as React from "react";
import { openModal, setSharedModalContent } from "common/shared-modal";

export function showForgotPasswordSuccessModal(email) {
  openModal("accounts");
  setSharedModalContent(
    "accounts",
    <ForgotPasswordSuccessModal email={email} />
  );
}

class ForgotPasswordSuccessModal extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="cope-core--modal--content">
        <div className="cope-core-text text-headeline-2">
          <h2>Email sent</h2>
        </div>
        <div className="cope-core-text text-body-xl">
          <p>
            We have sent an email to {this.props.email} with a link to reset
            your password. If you didn&rsquo;t receive it, try checking your
            spam folder.
          </p>
        </div>
      </div>
    );
  }
}

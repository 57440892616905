import "./style.scss";
import * as React from "react";

export default class SelectInput extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Check if input has errors
   *
   * @returns {boolean}
   */
  hasErrors() {
    return this.props.errors !== undefined && this.props.errors.length;
  }

  /**
   * Event handler input change
   *
   * @param {Object} event
   */
  handleInputChange = (event) => {
    const { onInput } = this.props;

    if (onInput != null) {
      onInput(event);
    }
  };

  render() {
    const {
      name,
      label,
      placeholder,
      value,
      options,
      errors,
      required,
      reverseKeys,
    } = this.props;
    return (
      <div
        className={`cope-core--form--field input-group ${name} ${
          this.hasErrors() ? "has-errors" : ""
        }`}
      >
        {label && (
          <div className="cope-core-text text-headline-5">
            <label className={required && "required"} htmlFor={name}>
              {label}
            </label>
          </div>
        )}
        <div className="error-wrap">
          <div className="select-wrap">
            <select
              type="text"
              id={name}
              name={name}
              value={value}
              aria-required={required ? "true" : false}
              onChange={this.handleInputChange}
            >
              <option value="">{placeholder}</option>
              {(reverseKeys
                ? Object.keys(options).reverse()
                : Object.keys(options)
              ).map((value) => (
                <option value={value} key={value}>
                  {options[value]}
                </option>
              ))}
            </select>
            <svg className="arrow" viewBox="0 0 33 16">
              <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
                <polyline
                  stroke="#00A3E0"
                  points="1 0 16.75 15.75 32.5 0"
                ></polyline>
              </g>
            </svg>
          </div>
          {this.hasErrors()
            ? errors.map((error) => (
                <div
                  key={error}
                  className="cope-core-text text-body-1 cope-core--form--error error-message"
                >
                  <p>{error}</p>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}

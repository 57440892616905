import * as React from "react";
import AccountModal from "./account-modal";
import { validateEmail } from "common/helpers";
import {
  closeModal,
  openModal,
  setSharedModalContent,
} from "common/shared-modal";

export function showLoginModal(registrationPage) {
  const modal = <LoginModal registrationPage={registrationPage} />;
  openModal("accounts");
  setSharedModalContent("accounts", modal);
}

function getErrorCode(errors, field) {
  const error = errors && errors.find((x) => x.field === field);
  return error && typeof error.code === "string" && error.code;
}

function getEmailErrorMessage(errorCode) {
  switch (errorCode) {
    case "notFound":
      return "No account exists for the email address.";
    default:
      return "Please enter a valid email address.";
  }
}

class LoginModal extends AccountModal {
  constructor() {
    super();

    this.email = React.createRef();
    this.password = React.createRef();
    this.state = {
      emailError: false,
      passwordError: false,
    };
  }

  handleErrors(response) {
    const emailError = getErrorCode(response.errors, "email");
    const passwordError = getErrorCode(response.errors, "password");
    this.setState({
      emailError,
      passwordError,
      generalError:
        !emailError &&
        !passwordError &&
        "We're sorry. An error has occured. Please try again.",
    });
  }

  clearErrors() {
    this.setState({ emailError: false, passwordError: false });
    super.clearErrors();
  }

  validate() {
    let valid = true;

    if (
      !(
        this.email.current &&
        this.email.current instanceof HTMLInputElement &&
        this.email.current.value &&
        typeof this.email.current.value === "string" &&
        validateEmail(this.email.current.value)
      )
    ) {
      this.setState({ emailError: true });
      valid = false;
    }

    if (
      !(
        this.password.current &&
        this.password.current instanceof HTMLInputElement &&
        this.password.current.value &&
        typeof this.password.current.value === "string" &&
        this.password.current.value.trim().length > 0
      )
    ) {
      this.setState({ passwordError: true });
      valid = false;
    }

    return valid;
  }

  submit() {
    BiopharmExtensions.events.trigger(BiopharmExtensions.events.UserLoginStart);
    return BiopharmExtensions.accounts
      .login(this.email.current.value, this.password.current.value)
      .then(() => {
        closeModal("accounts");
      })
      .catch((response) => {
        this.handleErrors(response);
      });
  }

  render() {
    return (
      <div className="cope-core--modal--content cope-core-signin-modal">
        <h2>Log in</h2>
        <form className="cope-core--form cope-core--modal--form">
          {this.state.generalError && (
            <div
              className="cope-core--modal--form-field-error"
              style={{ display: "block" }}
            >
              {this.state.generalError}
            </div>
          )}
          <div className="cope-core--form--field cope-core--modal--form-field">
            <label className={this.state.emailError ? "has-error" : ""}>
              <div className="cope-core-text text-headline-5">
                <p>Email address</p>
              </div>
              <input
                className="cope-core-patient-account-buttons--signin-modal--email"
                type="text"
                name="email"
                autoFocus
                ref={this.email}
              />
            </label>
            <p className="cope-core--form--error cope-core--modal--form-field-error">
              {getEmailErrorMessage(this.state.emailError)}
            </p>
          </div>
          <div className="cope-core--form--field cope-core--modal--form-field">
            <label className={this.state.passwordError ? "has-error" : ""}>
              <div className="cope-core-text text-headline-5">
                <p>Password</p>
              </div>
              <input
                className="cope-core-patient-account-buttons--signin-modal--password"
                type="password"
                name="password"
                ref={this.password}
              />
            </label>
            <p className="cope-core--form--error cope-core--modal--form-field-error">
              The password you entered is incorrect.
            </p>
          </div>
          <div className="cope-core-text text-headline-5">
            <a
              className="cope-core-patient-account-buttons__signin-modal--forgot-password underlined"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                BiopharmExtensions.accounts.openForgotPasswordModal();
              }}
            >
              Forgot password
            </a>
          </div>
          <div className="cope-core--modal--buttons">
            <button
              className="cope-core-button cope-core--button--primary cope-core-patient-account-buttons__signin-modal--submit"
              onClick={this.onSubmit.bind(this)}
            >
              Log in
            </button>
            <a
              href={this.props.registrationPage}
              className="cope-core-button cope-core--button--secondary cope-core-patient-account-buttons__create-account cope-core-patient-account-buttons__signin-modal--create-account"
            >
              Sign up
            </a>
          </div>
        </form>
      </div>
    );
  }
}

import "./style.scss";
import * as React from "react";

export default class ConfirmInput extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Check if input has errors
   *
   * @returns {boolean}
   */
  hasErrors() {
    return this.props.errors !== undefined && this.props.errors.length;
  }

  /**
   * Event handler input change
   *
   * @param {Object} event
   */
  handleInputChange = (event) => {
    const { onChange } = this.props;

    if (onChange != null) {
      onChange(event);
    }
  };

  render() {
    const { name, label, value, errors, required } = this.props;
    return (
      <div
        className={`cope-core--form--field input-group ${
          this.hasErrors() ? "has-errors" : ""
        }`}
      >
        <div className="input-wrap">
          <input
            type="checkbox"
            id={name}
            name={name}
            checked={value}
            aria-required={required ? "true" : false}
            onChange={this.handleInputChange}
          />
          <label htmlFor={name}>{label}</label>
        </div>
        {this.hasErrors()
          ? errors.map((error) => (
              <div
                key={error}
                className="cope-core-text text-body-1 cope-core--form--error error-message"
              >
                <p>{error}</p>
              </div>
            ))
          : null}
      </div>
    );
  }
}

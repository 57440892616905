import * as React from "react";
import { createRoot } from "react-dom/client";
import Modal from "common/components/partials/modal";

const REGISTRY = window.__shared_modals || {};
window.__shared_modals = REGISTRY;

const MODALS_ROOT = createModalsRoot();

function createModalsRoot() {
  const root = document.createElement("div");
  root.classList.add("cope-core--modal--root");
  document.body.appendChild(root);

  return createRoot(root);
}

export function createSharedModal(id) {
  REGISTRY[id] = React.createRef();
  MODALS_ROOT.render(<Modal id={`shared-modal--${id}`} ref={REGISTRY[id]} />);
}

export function setSharedModalContent(id, content) {
  const item = REGISTRY[id];
  if (item && item.current) {
    item.current.setChildren(content);
  }
}

export function openModal(id) {
  const item = REGISTRY[id];
  if (item && item.current) {
    item.current.open();
  }
}

export function closeModal(id) {
  const item = REGISTRY[id];
  if (item && item.current) {
    item.current.close();
  }
}

import * as React from "react";
import intl from "react-intl-universal";
import Button from "common/components/partials/inputs/button";
import ButtonGroup from "common/components/partials/inputs/button-group";
import UserInfo from "common/components/pages/user-info";
import ConfirmInput from "common/components/partials/inputs/confirm";
import {
  closeModal,
  openModal,
  setSharedModalContent,
} from "common/shared-modal";

import { requiresOptInMarketing } from "common/helpers";

const MODAL_ID = "accounts";

export function showUpdateForm() {
  setSharedModalContent(
    MODAL_ID,
    <UpdateInfo client={BiopharmExtensions.accounts.client} />
  );
  openModal(MODAL_ID);
}

class UpdateInfo extends UserInfo {
  constructor(props) {
    super(props);

    Object.assign(this.fieldLabels, {
      confirmAge: intl.get("register.errorLabels.confirmAge"),
    });

    if (requiresOptInMarketing()) {
      Object.assign(this.fieldLabels, {
        optInMarketing: intl.get("register.errorLabels.optInMarketing"),
      });
    }
  }

  getFormName() {
    return "Update Account Form";
  }

  getSuccessUrl() {} // Unused, but needs to be implemented since it's abstract

  getComponentClass() {
    return "cope-core--accounts--update-info";
  }

  getFormHeading() {
    return intl.get("updateInfo.title");
  }

  getPrivacyPolicy() {
    return intl.getHTML("updateInfo.privacyPolicy");
  }

  getScreeningInfoSectionHeading() {
    return intl.get("updateInfo.userTypeHeading");
  }

  async handleSuccess() {
    setSharedModalContent(
      "accounts",
      <div className="cope-core--accounts--update-info-success-modal">
        <div className="cope-core-text text-headline-2">
          <h2>All set!</h2>
        </div>
        <div className="cope-core-text text-body-xl">
          <p>Thanks for updating your information.</p>
        </div>
        <ButtonGroup>
          <Button
            primary
            size="sm"
            label="Close"
            onClick={() => {
              closeModal("accounts");
            }}
          />
        </ButtonGroup>
      </div>
    );
  }

  getApiEndpoint() {
    return this.props.client.updateCurrentUser;
  }

  getBirthYearModalBackAction() {
    return showUpdateForm;
  }

  getScrollElement() {
    return document.querySelector(
      "body > .cope-core--modal--root > .cope-core--modal--wrapper"
    );
  }

  getSubmitButtonLabel() {
    return intl.get("updateInfo.submitButtonLabel");
  }

  getValidationRules() {
    const rules = super.getValidationRules();

    Object.assign(rules, {
      confirmAge: "accepted",
    });

    if (requiresOptInMarketing()) {
      Object.assign(rules, {
        optInMarketing: "accepted",
      });
    }

    return rules;
  }

  getApiPayload() {
    const inputs = this.state.inputs;

    const payload = super.getApiPayload();

    Object.assign(payload, {
      confirmAgeOfMajority: inputs.confirmAge,
    });

    return payload;
  }

  getErrorMessages() {
    const errorMessages = super.getErrorMessages();

    Object.assign(errorMessages, {
      "accepted.confirmAge": intl.get("app.validation.required.confirmAge"),
    });

    if (requiresOptInMarketing()) {
      Object.assign(errorMessages, {
        "accepted.optInMarketing": intl.get(
          "register.errorLabels.optInMarketing"
        ),
      });
    }

    return errorMessages;
  }

  renderFormEnd() {
    const {
      errors,
      inputs: { optInMarketing, confirmAge },
    } = this.state;
    return (
      <div className="form-section">
        {requiresOptInMarketing() && (
          <ConfirmInput
            name="optInMarketing"
            value={optInMarketing}
            required
            errors={errors.optInMarketing}
            label={
              <div className="cope-core-text text-body-1">
                <p>{intl.get("app.inputs.optInMarketing.0")}</p>
                <p>{intl.getHTML("app.inputs.optInMarketing.1")}</p>
                <p className="include-required-asterisk">
                  {intl.get("app.inputs.optInMarketing.2")}
                </p>
              </div>
            }
            onChange={this.onInputChange}
          />
        )}

        <ConfirmInput
          name="confirmAge"
          value={confirmAge}
          required
          errors={errors.confirmAge}
          label={
            <div className="cope-core-text text-body-1">
              <p className="required">{super.getConfirmAge()}</p>
            </div>
          }
          onChange={this.onInputChange}
        />
      </div>
    );
  }
}

import * as React from "react";

export default class ButtonGroup extends React.Component {
  render() {
    return (
      <div className="button-wrap align-center has-two-buttons">
        {this.props.children}
      </div>
    );
  }
}

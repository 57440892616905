import dayjs from "dayjs";

/**
 * Validates that the input string is a valid date formatted as "mm/dd/yyyy"
 * From https://stackoverflow.com/questions/6177975/how-to-validate-date-with-format-mm-dd-yyyy-in-javascript#answer-6178341
 *
 * @param {string} value
 */
export function customDate(dateString) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

  // Parse the date parts to integers
  const parts = dateString.split("/");
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

/**
 * Check age against date
 *
 * @param {string} value
 * @param {number} age
 */
export function age(value, age) {
  return dayjs(value).add(age, "year").isBefore(dayjs());
}

/**
 * Check for valid zip code. Same regex used on backend
 *
 * @param {string} value
 */
export function zipCode(value) {
  return value.match(/^\d{5}(?:[-\s]\d{4})?$/) !== null;
}

export function phoneNumber(value) {
  return value.replace(/\D/g, "").match(/^\d{10}$/) !== null;
}

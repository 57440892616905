import * as React from "react";

import "./style.scss";

function getSizeClass(size) {
  switch (size) {
    case "sm":
      return "cope-core-button-type-sm";
    default:
      return "";
  }
}

export default class Button extends React.Component {
  render() {
    const {
      href,
      label,
      onClick,
      primary,
      opensInNewTab,
      size,
      title,
    } = this.props;
    return (
      <a
        className={`cope-core-button ${
          primary
            ? "cope-core--button--primary"
            : "cope-core--button--secondary"
        } ${getSizeClass(size)}`}
        href={href}
        title={title || label}
        target={opensInNewTab ? "_blank" : "_self"}
        onClick={onClick}
      >
        {label}
      </a>
    );
  }
}

import * as React from "react";
import {
  setObjectProperty,
  scrollToInput,
  firstErrorAll,
  getBirthyearThreshold,
  getSource,
  openBirthYearModal,
  closeBirthYearModal,
  parseServerErrors,
  handleErrorScrolling,
  trackEvent,
} from "common/helpers";
import dayjs from "dayjs";
import intl from "react-intl-universal";
import Validator from "validatorjs";
import RadioInput from "common/components/partials/inputs/radio";
import TextInput from "common/components/partials/inputs/text";
import SelectInput from "common/components/partials/inputs/select";
import SubmitButton from "common/components/partials/inputs/submit-button";
import { phoneNumber } from "common/custom-validation-rules";

import "./style.scss";

function getYearsObject(startYear, endYear) {
  return [...Array(endYear - startYear + 1).keys()]
    .map((key) => key + startYear)
    .reduce((arr, key) => {
      arr[key] = key;
      return arr;
    }, {});
}

function getOptionsObject(key) {
  return [...arguments].slice(1).reduce((arr, therapy) => {
    arr[therapy] = intl.get(key + "." + therapy);
    return arr;
  }, {});
}

function getValueForInput(input) {
  switch (input.type) {
    case "checkbox":
      return input.checked;
    case "radio":
      return input.form.querySelector(`input[name='${input.name}']:checked`)
        .value;
    default:
      return input.value;
  }
}

function createBirthYearThresholdValidator(threshold) {
  return (birthYear) => {
    const today = dayjs();
    const birthYearPlusThreshold = dayjs(birthYear).add(threshold, "year");
    return (
      birthYearPlusThreshold.isBefore(today) ||
      birthYearPlusThreshold.isSame(today)
    );
  };
}

export default class UserInfoForm extends React.Component {
  constructor(props) {
    super(props);

    if (this.constructor.name === "UserInfoForm") {
      throw "UserInfoForm is abstract!";
    }

    const abstractMethods = [
      "getComponentClass",
      "getFormHeading",
      "getPrivacyPolicy",
      "getScreeningInfoSectionHeading",
      "getApiEndpoint",
      "getSuccessUrl",
      "getSubmitButtonLabel",
      "getFormName",
      "getBirthYearModalBackAction",
    ];
    abstractMethods.forEach((method) => {
      if (typeof this[method] !== "function") {
        throw method + " must be implemented!";
      }
    });

    this.state = {
      loading: false,
      inputs: {
        type: "",
        birthYear: "",
        onTherapy: null,
        therapy: "",
        otherTherapy: "",
        reason: "",
        optInTextMessaging: false,
        confirmOptInTextMessaging: "",
        phoneNumber: "",
        diagnosis: "",
        optInMarking: false,
        confirmAge: false,
      },
      errors: {},
    };

    this.fieldLabels = {
      type: intl.get("userInfo.inputs.type.errorLabel"),
      birthYear: intl.get("userInfo.inputs.birthYear.errorLabel"),
      diagnosis: intl.get("userInfo.inputs.diagnosis.errorLabel"),
      onTherapy: intl.get("userInfo.inputs.onTherapy.errorLabel"),
      therapy: intl.get("userInfo.inputs.therapy.errorLabel"),
      otherTherapy: intl.get("userInfo.inputs.therapy.errorLabel"),
      reason: intl.get("userInfo.inputs.reason.errorLabel"),
      phoneNumber: intl.get("userInfo.inputs.phoneNumber.errorLabel"),
      confirmOptInTextMessaging: intl.get(
        "userInfo.inputs.confirmOptInTextMessaging.errorLabel"
      ),
    };

    this.customServerErrorMessages = {
      birthYear: intl.get(
        "userInfo.inputs.birthYear.errors.tooRecent" + getBirthyearThreshold()
      ),
    };

    // Enum of user types. Each value must be unique, because this is passed to GA
    this.types = {
      caregiver: 0,
      patient: 1,
      other: 2,
    };
  }

  trackFieldCompletion(fieldLabel) {
    this.completedFields = this.completedFields || [];
    if (this.completedFields.indexOf(fieldLabel) < 0) {
      this.completedFields.push(fieldLabel);

      trackEvent(this.getFormName(), "Completed", fieldLabel);
    }
  }

  registerCustomValidators() {
    Validator.register(
      "phoneNumber",
      phoneNumber,
      intl.get("app.validation.invalid.phoneNumber")
    );

    Validator.register(
      "tooRecent",
      createBirthYearThresholdValidator(getBirthyearThreshold()),
      intl.get(
        "userInfo.inputs.birthYear.errors.tooRecent" + getBirthyearThreshold()
      )
    );
  }

  getScrollElement() {
    return document.documentElement;
  }

  getValidationRules() {
    const inputs = this.state.inputs;
    const rules = {
      type: "required",
    };

    if (inputs.type) {
      if (inputs.type === "other") {
        rules.reason = "required";
      } else if (inputs.type === "patient") {
        rules.birthYear = "required|tooRecent";
      } else {
        rules.birthYear = "required";
      }
    }

    if (inputs.birthYear) {
      rules.diagnosis = "required";
    }

    if (inputs.diagnosis && inputs.diagnosis !== "none") {
      rules.onTherapy = "required";
    }

    if (inputs.onTherapy === "yes") {
      rules.therapy = "required";
    }

    if (inputs.therapy === "other") {
      rules.otherTherapy = "required";
    }

    if (inputs.optInTextMessaging) {
      rules.phoneNumber = "required|phoneNumber";
      rules.confirmOptInTextMessaging = "accepted";
    }

    return rules;
  }

  getErrorMessages() {
    const errorMessages = {
      "required.type": intl.get("userInfo.inputs.type.errors.required"),
      "required.phoneNumber": intl.get("app.validation.required.phoneNumber"),
      "accepted.confirmOptInTextMessaging": intl.get(
        "app.validation.required.confirmOptInTextMessaging"
      ),
      "required.onTherapy": intl.get(
        "userInfo.inputs.onTherapy.errors.required"
      ),
    };

    if (this.state.inputs.type === "patient") {
      Object.assign(errorMessages, {
        "required.birthYear": intl.get(
          "app.validation.required.birthYearPatient"
        ),
        "required.diagnosis": intl.get(
          "app.validation.required.diagnosisPatient"
        ),
        "required.therapy": intl.get("app.validation.required.therapyPatient"),
        "required.otherTherapy": intl.get(
          "userInfo.inputs.otherTherapy.errors.requiredPatient"
        ),
      });
    } else if (this.state.inputs.type === "caregiver") {
      Object.assign(errorMessages, {
        "required.birthYear": intl.get(
          "app.validation.required.birthYearCaregiver"
        ),
        "required.diagnosis": intl.get(
          "app.validation.required.diagnosisCaregiver"
        ),
        "required.therapy": intl.get(
          "app.validation.required.therapyCaregiver"
        ),
        "required.otherTherapy": intl.get(
          "userInfo.inputs.otherTherapy.errors.requiredCaregiver"
        ),
      });
    } else if (this.state.inputs.type === "other") {
      Object.assign(errorMessages, {
        "required.reason": intl.get("register.validation.required.reason"),
      });
    }

    return errorMessages;
  }

  performCustomSubmission(validation) {} // Virtual

  async handleSubmit(event) {
    event.preventDefault();

    this.registerCustomValidators();

    const inputs = this.state.inputs;
    const validation = new Validator(
      inputs,
      this.getValidationRules(),
      this.getErrorMessages()
    );

    if (validation.passes()) {
      await this.makeApiCall();
    } else {
      // Get validation errors
      const errors = firstErrorAll(validation.errors.all());
      this.setState({ errors }, () => {
        handleErrorScrolling(errors, this.getScrollElement());
      });
    }
  }

  getApiPayload() {
    const inputs = this.state.inputs;
    return {
      source: `${getSource()}-signup`,
      type: inputs.type,
      birthYear: inputs.birthYear,
      birthDate: "",
      diagnosis: inputs.diagnosis,
      therapyStatus:
        inputs.therapy === "other"
          ? inputs.otherTherapy
          : inputs.onTherapy === "no"
          ? "none"
          : inputs.therapy,
      reason: inputs.reason,
      optInTextMessaging: inputs.optInTextMessaging,
      phoneNumber: inputs.phoneNumber,
    };
  }

  async makeApiCall() {
    if (!this.props.isAEMEditMode) {
      this.setState({ loading: true });
      try {
        await this.getApiEndpoint()(this.getApiPayload());
      } catch (err) {
        const errors = parseServerErrors(
          err._response,
          (error) =>
            this.customServerErrorMessages.hasOwnProperty(error.field) &&
            this.customServerErrorMessages[error.field]
        );

        this.setState({ loading: false, errors }, () => {
          handleErrorScrolling(errors, this.getScrollElement(), errors.global);
        });

        return false;
      }

      this.setState({ loading: false });
      await this.handleSuccess();
    }

    return true;
  }

  async handleSuccess() {
    trackEvent(
      this.getFormName(),
      "Submitted",
      "",
      this.types[this.state.inputs["type"]]
    );
    window.location.href = this.getSuccessUrl();
  }

  userIsOldEnough(birthYear) {
    const userType = this.state.inputs && this.state.inputs.type;
    return (
      (userType && userType !== "patient") ||
      createBirthYearThresholdValidator(getBirthyearThreshold())(birthYear)
    );
  }

  onInputChange = (event) => {
    const target = event.target;
    const value = getValueForInput(event.target);
    const name = target.name;

    this.trackFieldCompletion(this.fieldLabels[name]);

    this.setState((prevState) => {
      const newInputsState = setObjectProperty(prevState.inputs, name, value);

      if (name === "type") {
        newInputsState.birthYear = "";
        newInputsState.diagnosis = "";
      }

      if (name === "type" || name === "diagnosis") {
        newInputsState.onTherapy = null;
      }

      if (name === "type" || name === "diagnosis" || name === "onTherapy") {
        newInputsState.therapy = "";
        newInputsState.otherTherapy = "";
        newInputsState.reason = "";
      }

      if (name === "birthYear") {
        newInputsState.birthYear = this.handleBirthYearChange();
      }

      return {
        inputs: newInputsState,
      };
    });
  };

  handleBirthYearChange() {
    const birthYear = this.state.inputs.birthYear;
    if (!!birthYear && !this.userIsOldEnough(birthYear)) {
      openBirthYearModal(this.onBirthYearModalBackButtonClick.bind(this));
      return "";
    }

    return birthYear;
  }

  scrollToInput = (event, input) => {
    event.preventDefault();

    scrollToInput(input, this.getScrollElement());
  };

  onBirthYearModalBackButtonClick(event) {
    event.preventDefault();

    closeBirthYearModal();

    this.getBirthYearModalBackAction()();
  }

  renderUserTypeControls() {
    const {
      errors,
      inputs: { type },
    } = this.state;
    return (
      <SelectInput
        name="type"
        label={intl.get("userInfo.inputs.type.prompt")}
        value={type}
        errors={errors.type}
        placeholder="Select"
        options={Object.keys(this.types).reduce((obj, key) => {
          obj[key] = intl.get("userInfo.inputs.type.options." + key);
          return obj;
        }, {})}
        required
        onInput={this.onInputChange}
      />
    );
  }

  renderGreeting() {
    const {
      inputs: { type },
    } = this.state;
    return (
      type &&
      type !== "other" && (
        <div className="cope-core-text text-body-1">
          <p className="greeting">{intl.get("userInfo.greetings." + type)}</p>
        </div>
      )
    );
  }

  renderBirthYearControls() {
    const {
      errors,
      inputs: { type, birthYear },
    } = this.state;
    return (
      <div>
        {type && type !== "other" && (
          <SelectInput
            name="birthYear"
            label={intl.get("userInfo.inputs.birthYear.prompt." + type)}
            value={birthYear}
            errors={errors.birthYear}
            placeholder="Select"
            options={getYearsObject(1900, new Date().getFullYear())}
            reverseKeys
            required
            onInput={this.onInputChange}
          />
        )}
      </div>
    );
  }

  getDiagnosisOptions() {
    const sourceId = getSource();

    switch (sourceId) {
      case "norditropin":
        return [
          "cghd",
          "aghd",
          "smallgest",
          "noonan",
          "turner",
          "praderwilli",
          "iss",
          "none",
        ];

      case "sogroya":
        return ["cghd", "aghd", "none"];

      default:
        return [""];
    }
  }

  renderDiagnosisControls() {
    const {
      inputs: { type, diagnosis },
      errors,
    } = this.state;
    return (
      type &&
      type != "other" && (
        <SelectInput
          name="diagnosis"
          label={intl.get("userInfo.inputs.diagnosis.prompt." + type)}
          value={diagnosis}
          errors={errors.diagnosis}
          placeholder="Select"
          options={getOptionsObject(
            "userInfo.inputs.diagnosis.options",
            ...this.getDiagnosisOptions()
          )}
          required
          onInput={this.onInputChange}
        />
      )
    );
  }

  renderOnTherapyControls() {
    const {
      errors,
      inputs: { type, diagnosis, onTherapy, birthYear },
    } = this.state;
    return (
      type &&
      type !== "other" &&
      diagnosis &&
      diagnosis !== "none" &&
      birthYear && (
        <RadioInput
          name="onTherapy"
          label={intl.get("userInfo.inputs.onTherapy.prompt." + type)}
          value={onTherapy}
          errors={errors.onTherapy}
          options={[
            {
              label: intl.get("userInfo.inputs.onTherapy.options.yes"),
              extraControls: this.renderTherapyControls(),
              value: "yes",
            },
            {
              label: intl.get("userInfo.inputs.onTherapy.options.no"),
              value: "no",
            },
          ]}
          required
          onChange={this.onInputChange}
        />
      )
    );
  }

  getTherapyOptions() {
    const sourceId = getSource();

    switch (sourceId) {
      case "norditropin":
        return ["norditropin", "other"];

      case "sogroya":
        return ["norditropin", "sogroya", "other"];

      default:
        return [""];
    }
  }

  renderTherapyControls() {
    const {
      inputs: { type, onTherapy, therapy, otherTherapy },
      errors,
    } = this.state;
    return (
      type &&
      type !== "other" &&
      onTherapy === "yes" && (
        <div>
          <SelectInput
            name="therapy"
            label={intl.get("userInfo.inputs.therapy.prompt")}
            value={therapy}
            errors={errors.therapy}
            placeholder="Select"
            options={getOptionsObject(
              "userInfo.inputs.therapy.options",
              ...this.getTherapyOptions()
            )}
            required
            onInput={this.onInputChange}
          />
          {therapy && therapy == "other" && (
            <TextInput
              name="otherTherapy"
              label={intl.get("userInfo.inputs.otherTherapy.prompt." + type)}
              value={otherTherapy}
              required
              errors={errors.otherTherapy}
              onInput={this.onInputChange}
            />
          )}
        </div>
      )
    );
  }

  renderReasonControls() {
    const {
      errors,
      inputs: { type, reason },
    } = this.state;
    return (
      type &&
      type === "other" && (
        <RadioInput
          name="reason"
          label={intl.getHTML("userInfo.inputs.reason.prompt")}
          value={reason}
          errors={errors.reason}
          options={[
            {
              label: intl.getHTML(
                `userInfo.inputs.reason.options.${[getSource()]}.taking`
              ),
              value: "taking",
            },
            {
              label: intl.getHTML(
                `userInfo.inputs.reason.options.${[getSource()]}.considering`
              ),
              value: "considering",
            },
            {
              label: intl.getHTML(
                `userInfo.inputs.reason.options.${[getSource()]}.other`
              ),
              value: "other",
            },
          ]}
          required
          suppressRequiredLabel
          onChange={this.onInputChange}
        />
      )
    );
  }

  renderScreeningInfoSection() {
    return (
      <div className="form-section">
        <div className="cope-core-text text-headline-3">
          <h3>{this.getScreeningInfoSectionHeading()}</h3>
        </div>
        <div className="cope-core-text text-body-2">
          <p className="required-text">
            {intl.getHTML("register.requiredText")}
          </p>
        </div>
        {this.renderUserTypeControls()}
        {this.renderGreeting()}
        {this.renderBirthYearControls()}
        {this.renderDiagnosisControls()}
        {this.renderOnTherapyControls()}
        {this.renderReasonControls()}
      </div>
    );
  }

  getConfirmAge() {
    if (getBirthyearThreshold() === 18) {
      return intl.getHTML("app.inputs.confirmAgeEighteen");
    } else {
      return intl.getHTML("app.inputs.confirmAge");
    }
  }

  renderFormPreface() {} // Virtual

  renderFormMiddle() {} // Virtual

  renderFormEnd() {} // Virtual

  renderExtraFormButtons() {} // Virtual

  render() {
    const { loading, errors } = this.state;
    return (
      <div
        className={`cope-core--accounts--user-info-form ${this.getComponentClass()}`}
      >
        <div className="cope-core-text text-headline-1">
          <h1>{this.getFormHeading()}</h1>
        </div>
        <div className="cope-core-text text-body-xl">
          <p>{this.getPrivacyPolicy()}</p>
        </div>
        {this.renderFormPreface()}
        <form
          className="cope-core--form"
          onSubmit={this.handleSubmit.bind(this)}
          noValidate
        >
          {Object.keys(errors).length > 1 || errors.global ? (
            <div className="cope-core-text text-body-1 error-summary">
              {errors.global ? (
                <p>{errors.global}</p>
              ) : (
                <div>
                  <p className="cope-core--form--error">
                    {intl.get("app.errorSummary.pleaseCorrect")}
                  </p>
                  <ul>
                    {Object.keys(errors).map((field) => (
                      <li key={field}>
                        <a
                          href="#"
                          onClick={(event) => this.scrollToInput(event, field)}
                        >
                          {this.fieldLabels[field]}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : null}

          <div className="info-form">
            {this.renderScreeningInfoSection()}
            {this.renderFormMiddle()}
          </div>

          {this.renderFormEnd()}

          <div className="submit-wrap">
            <SubmitButton
              label={this.getSubmitButtonLabel()}
              loading={loading}
            />
            {this.renderExtraFormButtons()}
          </div>
        </form>
      </div>
    );
  }
}

import { createClient } from "./client/client";
import { getOAuth2Grants, setOAuth2Grant } from "./client/handleOAuth2Grants";
import {
  isPublishMode,
  getParameterByName,
  getSiteRoot,
  redirect,
} from "common/helpers";
import {
  showProfileCheckIfNecessary,
  clearProfileCheckSessionStorage,
} from "common/components/pages/user-profile-check";
import { createSharedModal } from "common/shared-modal";
import { showLoginModal } from "common/components/partials/login-modal";
import { showForgotPasswordModal } from "common/components/partials/forgot-password-modal";

import intl from "react-intl-universal";
import en from "common/i18n/en.json";

const CURRENT_PROFILE_VERSION = "v1";

const client = createClient({
  apiUrl: BiopharmExtensions.util.url.getAccountSiteBaseUrl(),
  oauth2Grants: getOAuth2Grants(),
  onNewOAuth2Grant: setOAuth2Grant,
});

function getUser() {
  const oauth2Grants = getOAuth2Grants();

  if (!oauth2Grants.length) {
    // No grants = we should not even *attempt* to hit the API
    // Tell NovoMedLink there is no user. NML will render the default
    // "LOGIN" and "REGISTER" links in the header.
    return Promise.resolve(null);
  }

  try {
    return new Promise((resolve) => {
      client
        .getCurrentUser()
        .then(resolve)
        .catch(() => resolve(null));
    });
  } catch (err) {
    return Promise.resolve(null);
  }
}

async function login(email, password) {
  return new Promise((resolve, reject) => {
    client
      .logIn({ email, password })
      .then(() => {
        client
          .getCurrentUser()
          .then((user) => {
            BiopharmExtensions.events.trigger(
              BiopharmExtensions.events.UserLoginComplete
            );

            resolve(user);

            if (!redirectIfNecessary()) {
              checkProfileVersion();
            }
          })
          .catch(reject);
      })
      .catch(reject);
  });
}

function logout() {
  localStorage.removeItem("norditropinOAuth2Grant");
  BiopharmExtensions.events.trigger(BiopharmExtensions.events.UserLogout);
}

function register(registrationUrl, source, returnUrl) {
  BiopharmExtensions.events.trigger(
    BiopharmExtensions.events.UserRegisterStart
  );
  if (registrationUrl && source) {
    const parameters = {
      returnUrl: returnUrl || window.location.pathname,
      previousUrl: window.location.pathname,
      source,
    };
    const query = Object.keys(parameters)
      .filter((key) => parameters[key])
      .map((key) => `${key}=${encodeURIComponent(parameters[key])}`)
      .join("&");

    window.location.href = registrationUrl + (query ? "?" + query : "");
  }
}

function handleActionParameter() {
  const actionParameter = getParameterByName("action");
  const decodedAction =
    typeof actionParameter === "string" && decodeURIComponent(actionParameter);
  switch (decodedAction) {
    case "login":
      getUser().then((user) => {
        if (user) {
          redirectIfNecessary();
        } else {
          openLoginModal();
        }
      });
      return;
    case "password":
      openForgotPasswordModal();
      return;
    case "check-profile":
      clearProfileCheckSessionStorage();
      displayUserProfileCheck();
      return;
  }
}

function openLoginModal() {
  const registrationPage = [
    ...document.querySelectorAll(".cope-core-patient-account-buttons"),
  ]
    .map((x) => x.dataset["registrationPage"])
    .find((x) => x);

  showLoginModal(registrationPage);
}

function openForgotPasswordModal() {
  showForgotPasswordModal();
}

function authenticateIfNecessary() {
  if (!isPublishMode()) return;

  return new Promise((resolve, reject) => {
    getUser().then((user) => {
      if (!user) {
        reject();

        const targetUrl = new URL(getSiteRoot(window.location));

        targetUrl.searchParams.append("action", "login");
        targetUrl.searchParams.append("returnUrl", window.location.href);

        redirect(targetUrl);
      } else {
        resolve(user);
      }
    });
  });
}

function redirectIfNecessary() {
  const returnUrlParameter = getParameterByName("returnUrl");
  const decodedReturnUrl =
    typeof returnUrlParameter === "string" &&
    decodeURIComponent(returnUrlParameter);
  if (decodedReturnUrl) {
    redirect(decodedReturnUrl);
    return true;
  }

  return false;
}

function displayUserProfileCheck() {
  showProfileCheckIfNecessary();
}

function checkProfileVersion() {
  getUser().then((user) => {
    if (
      !isPublishMode() ||
      !user ||
      user.profileVersion === CURRENT_PROFILE_VERSION
    ) {
      return;
    }

    displayUserProfileCheck();
  });
}

window.BiopharmExtensions = window.BiopharmExtensions || {};
window.BiopharmExtensions.accounts = window.BiopharmExtensions.accounts || {
  client,
  getUser,
  login,
  logout,
  register,
  authenticateIfNecessary,
  checkProfileVersion,
  redirectIfNecessary,
  openLoginModal,
  openForgotPasswordModal,
};

document.addEventListener("DOMContentLoaded", () => {
  // Need to wait until modals have been bound, which happens 1s after page load
  setTimeout(handleActionParameter, 1200);

  checkProfileVersion();
});

intl.init({ currentLocale: "en", locales: { en } }).then(() => {
  createSharedModal("accounts");
});
